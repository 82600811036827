// service for crypto news
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

// get headers from Rapid Api
const cryptoApiHeaders = {
    'x-bingapis-sdk': 'true',
    'x-rapidapi-host': 'bing-news-search1.p.rapidapi.com',
    'x-rapidapi-key': process.env.REACT_APP_BING_NEWS_KEY
}

// get the base URL
const baseUrl = 'https://bing-news-search1.p.rapidapi.com';

// create request function. Takes url and adds the headers
const createRequest = (url) => ({url, headers:cryptoApiHeaders})

export const cryptoNewsApi = createApi({
    reducerPath : 'cryptoNewsApi',
    baseQuery: fetchBaseQuery({baseUrl}),
    endpoints: (builder)=>({
        getCryptoNews:builder.query({
            query:({newsCategory, count}) => createRequest(`/news/search?q=${newsCategory}&safeSearch=Off&textFormat=Raw&freshness=Day&count=${count}`)
        })
    })
})

export const {useGetCryptoNewsQuery} = cryptoNewsApi;
